import services from '../services';
import {useRouter} from 'next/router';
import {useState, useEffect} from 'react';
import DefaultLoader from './UI/DefaultLoader';

const PublicPage = ({children}) => {
    const router = useRouter();

    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (router.isReady) {
            check();
        }
    }, [router.isReady]);

    const check = async () => {
        await services.auth.currAuthUser().then(() => {
            if (router.query.widget === 'true') {
                router.push('/my-registry?popup=open');
            } else {
                router.push('/my-registry');
            }
        }).catch(() => {
            setReady(true);
        });
    };

    if (!ready) {
        return <DefaultLoader/>;
    }

    return children;
};

export default PublicPage;